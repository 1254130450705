<template>
  <div class="solution">
    <div class="banner">
      <img src="@/assets/solution/banner.jpg" />
    </div>
    <div class="tabs">
      <div class="tabs-wrapper">
        <div :class="currIndex === 1 ? 'active' : ''" @click="currIndex = 1">智慧城管</div>
        <div :class="currIndex === 2 ? 'active' : ''" @click="currIndex = 2">垃圾治理</div>
        <div :class="currIndex === 3 ? 'active' : ''" @click="currIndex = 3">智慧环卫</div>
        <div :class="currIndex === 4 ? 'active' : ''" @click="currIndex = 4">智慧公厕</div>
        <div :class="currIndex === 5 ? 'active' : ''" @click="currIndex = 5">视频AI分析</div>
      </div>
    </div>
    <div class="citymanager" v-show="currIndex === 1">
      <div class="citymanager-wrapper" >
        <div class="title animate__animated" :class="animateShow1.show1 ? 'animate__fadeInUp' : ''">智慧城管</div>
        <div class="content animate__animated" :class="animateShow1.show1 ? 'animate__fadeInUp' : ''">
          <img src="@/assets/solution/zhcg.jpg" />
          <div class="info">
            “智慧城管”建设将立足于城市管理体制、机制和方式的创新，依托地理信息、物联网、云计算、大数据等现代信息技术，秉承“互联网+”理念，整合已建或将建的政府公共资源，构建统一的城市综合管理平台，实现城市管理行业监管部门间的协同运作，提升城市管理效能，通过对城市管理对象的实时信息感知、市容动态分析、辅助综合决策，支撑城市治理的精细化、精准化管理工作，强化城市应急指挥调度能力，打造“共建共管”的城市管理运营新模式和应用新典范。
          </div>
        </div>
      </div>
      <div class="process">
        <div class="process-wrapper">
          <div class="pro-bar animate__animated" :class="animateShow1.show2 ? 'animate__fadeInUp' : ''">
            <div class="pro-item">
              <div class="item-img"><img src="@/assets/solution/proImg1.png" /></div>
              <div class="item-text">
                <p class="t">一站式管理</p>
                <p>一张图管理</p>
                <p>一张表评估</p>
                <p>一平台指挥</p>
              </div>
            </div>
            <div class="pro-item">
              <div class="item-img"><img src="@/assets/solution/proImg2.png" /></div>
              <div class="item-text">
                <p class="t">多元化服务</p>
                <p>“贴心城管”APP</p>
              </div>
            </div>
            <div class="pro-item">
              <div class="item-img"><img src="@/assets/solution/proImg3.png" /></div>
              <div class="item-text">
                <p class="t">“数字城管”<br />智能化升级</p>
                <p>“数字城管”2.0</p>
                <p>“智慧城管”数据采集</p>
              </div>
            </div>
            <div class="pro-item">
              <div class="item-img"><img src="@/assets/solution/proImg4.png" /></div>
              <div class="item-text">
                <p class="t">非现场执法</p>
                <p>违停抓拍</p>
                <p>店外店管理</p>
              </div>
            </div>
            <div class="pro-item">
              <div class="item-img"><img src="@/assets/solution/proImg5.png" /></div>
              <div class="item-text">
                <p class="t">垃圾治理</p>
                <p>多流程监管</p>
                <p>计量收费</p>
              </div>
            </div>
            <div class="pro-item">
              <div class="item-img"><img src="@/assets/solution/proImg6.png" /></div>
              <div class="item-text">
                <p class="t">智慧环卫</p>
                <p>智慧环卫</p>
                <p>智慧公厕</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="system animate__animated" :class="animateShow1.show3 ? 'animate__fadeInUp' : ''">
        <div class="title">系统功能</div>
        <img src="@/assets/solution/system.jpg" />
      </div>
    </div>
    <div class="garbage" v-show="currIndex === 2">
      <div class="garbage-wrapper">
        <div class="title animate__animated" :class="animateShow2.show1 ? 'animate__fadeInUp' : ''">垃圾治理</div>
        <div class="info animate__animated" :class="animateShow2.show1 ? 'animate__fadeInUp' : ''">
          围绕生活垃圾分类长效管理的目标，遵循“用数据说话，用数据决策，用数据管理，用数据创新”的理念，充分利用物联感知、北斗高精度定位、大数据、人工智能、5G等信息技术，建设生活垃圾分类监管大数据平台，丰富垃圾分类工作管理手段，提升垃圾分类管理水平，提高垃圾分类管理效率，最终实现生活垃圾治理工作规范化、智能化、精细化。
        </div>
      </div>
      <div class="thinking animate__animated" :class="animateShow2.show2 ? 'animate__fadeInUp' : ''">
        <div class="title">建设思路</div>
        <img src="@/assets/solution/thinking.png" />
      </div>
      <div class="introduce animate__animated" :class="animateShow2.show3 ? 'animate__fadeInUp' : ''">
        <div class="title">系统介绍</div>
        <img src="@/assets/solution/introduce.jpg" />
      </div>
    </div>
    <div class="sanitation" v-show="currIndex === 3">
      <div class="sanitation-wrapper">
        <div class="title animate__animated" :class="animateShow3.show1 ? 'animate__fadeInUp' : ''">智慧环卫</div>
        <div class="top animate__animated" :class="animateShow3.show1 ? 'animate__fadeInUp' : ''">
          <img src="@/assets/solution/zhhw.jpg" />
          <div class="info">
            智慧环卫利用GIS、GPS、视频监控、RFID技术，根据环卫作业规范化、精细化、智能化管理的要求，从环卫工作的基础作业管理、作业人员车辆管理、渣土车辆运输监管、作业结果考核等方面对城市环卫工作进行信息化升级和管理，实现对道路卫生状况、环卫设施和作业人员、作业车辆、作业质量等全方位、全时段监管的智能化管理新模式助力环卫部门进行环卫信息化升级，规范环卫管理，重塑环卫新模式，创造绿色健康美好城市。
          </div>
        </div>
        <div class="bottom animate__animated" :class="animateShow3.show1 ? 'animate__fadeInUp' : ''">
          <div class="item">
            <img src="@/assets/solution/plat_1.jpg" />
            <p>环卫作业车辆车载视频监控及配套设备<br />搭建车载视频智能管理平台</p>
          </div>
          <div class="item">
            <img src="@/assets/solution/plat_2.jpg" />
            <p>建设集监管、分析、指挥为一体的智慧环卫系统平台，全面实现监管智能化、决策精准化、评估科学化、业务协同化。</p>
          </div>
        </div>
      </div>
      <div class="characteristic">
        <div class="title animate__animated" :class="animateShow3.show2 ? 'animate__fadeInUp' : ''">系统特色</div>
        <div class="content animate__animated" :class="animateShow3.show2 ? 'animate__fadeInUp' : ''">
          <div class="card">
            <p class="lable">实时定位</p>
            <div>
              <img src="@/assets/solution/cha1.png" alt="实时定位" title="实时定位" />
              <p>实时掌握环卫人员、环卫车辆具体去向和环卫设施的位置信息。</p>
            </div>
          </div>
          <div class="card">
            <p class="lable">工作动态</p>
            <div>
              <img src="@/assets/solution/cha2.png" alt="工作动态" title="工作动态" />
              <p>实时了解智能感知设备的工作常态和告警状况，提高工作效能。</p>
            </div>
          </div>
          <div class="card">
            <p class="lable">可视管理</p>
            <div>
              <img src="@/assets/solution/cha3.png" alt="可视管理" title="可视管理" />
              <p>以视频形式查看环卫工作状况，掌握环境动态变化和管理实效，实现可视管理</p>
            </div>
          </div>
          <div class="card">
            <p class="lable">动态分析</p>
            <div>
              <img src="@/assets/solution/cha4.png" alt="动态分析" title="动态分析" />
              <p>以数据动态显示形式，展示环卫领域管理情况，了解环卫管理工作实时状况。</p>
            </div>
          </div>
          <div class="card">
            <p class="lable">信息查询</p>
            <div>
              <img src="@/assets/solution/cha5.png" alt="信息查询" title="信息查询" />
              <p>查询环卫全要素实时信息、历史信息、动态变化信息，达到环卫管理全要素信息的查询</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toilet" v-show="currIndex === 4">
      <div class="toilet-wrapper">
        <div class="title animate__animated" :class="animateShow4.show1 ? 'animate__fadeInUp' : ''">智慧公厕</div>
        <div class="info animate__animated" :class="animateShow4.show1 ? 'animate__fadeInUp' : ''">
          以“美丽公厕”建设为中心，以公厕精细化管理为目标，充分利用物联感知、视频分析、移动互联等新一代信息技术，通过公厕智能化改造，搭建智慧公厕云平台，实时感知公厕运行状态，动态掌握公厕管理信息，提供便捷导厕服务，切实增强公厕管理能力，提升公厕服务水平，实现公厕管理信息化、智能化、精细化。
        </div>
      </div>
      <div class="reform">
        <div class="title animate__animated" :class="animateShow4.show2 ? 'animate__fadeInUp' : ''">公厕改造</div>
        <img class="animate__animated" :class="animateShow4.show2 ? 'animate__fadeInUp' : ''" src="@/assets/solution/reform.png" />
      </div>
      <div class="introduce">
        <div class="title animate__animated" :class="animateShow4.show3 ? 'animate__fadeInUp' : ''">系统介绍</div>
        <img class="animate__animated" :class="animateShow4.show3 ? 'animate__fadeInUp' : ''" src="@/assets/solution/introduce.png" />
      </div>
    </div>
    <div class="video" v-show="currIndex === 5">
      <div class="title animate__animated" :class="animateShow5.show1 ? 'animate__fadeInUp' : ''">视频AI分析</div>
      <div class="content1 animate__animated" :class="animateShow5.show1 ? 'animate__fadeInUp' : ''">
        <div class="left">
          <p class="label">非接触式执法子系统</p>
          <p class="text">
            城市管理违法行为具有强流动性、非特定性，导致违法行为发现不及时、取证不完整、现场执法易产生纠纷等，使得城市执法工作开展难、不被市民支持和理解。系统利用视频结构化和AI视频识别技术，在发现城市管理违法问题时，同时对违法责任主体进行非接触式执法取证，识别责任主体信息，辅以“失信联合惩戒”，实现传统现场执法方式向非接触式执法转变。
          </p>
        </div>
        <img src="@/assets/solution/video_img1.png" />
      </div>
      <div class="content2">
        <div class="content2-wrapper animate__animated" :class="animateShow5.show2 ? 'animate__fadeInUp' : ''">
          <div class="left">
            <p class="label">门前三包管理子系统</p>
            <div class="text">
              <p>门前三包是指包卫生、包绿化、包秩序，以责任主体信息库为基础，明确每个责任主体的三包区域范围和界限。</p>
              <p>发现责任区域的违规情况时，系统会第一时间向责任主体发送提示信息，督促其立即进行整改。</p>
              <p>建立责任主体信用档案，内部作为精细管理及执法自由裁量的参考依据，外部进行信用体系的共享交换及联合惩戒。</p>
              <p>辅助对其他乱停乱靠、乱摆摊设点、乱挖掘等影响市容秩序的行为进行监督。</p>
            </div>
          </div>
          <img src="@/assets/solution/video_img2.png" />
        </div>
      </div>
      <div class="content3">
        <div class="content3-wrapper">
          <div class="left">
            <p class="label animate__animated" :class="animateShow5.show3 ? 'animate__fadeInUp' : ''">渣土车管理子系统</p>
            <p class="text animate__animated" :class="animateShow5.show3 ? 'animate__fadeInUp' : ''">帮助政府监管部门提高执法效率，AI智能视频分析+采集车辆结构化数据，通过人工智能算法自动识别各类违章行为，大幅提高执法部门获取违章线索的效率。</p>
            <img class="animate__animated" :class="animateShow5.show3 ? 'animate__fadeInUp' : ''" src="@/assets/solution/video_img3.png">
          </div>
          <div class="right">
            <p class="label animate__animated" :class="animateShow5.show3 ? 'animate__fadeInUp' : ''">垃圾投放管理子系统</p>
            <p class="text animate__animated" :class="animateShow5.show3 ? 'animate__fadeInUp' : ''">利用人工智能、移动互联、大数据、物联网等高新技术，对垃圾清理、倾倒、清运等进行全生命周期智能化管控，实现对垃圾满溢、暴露垃圾等违规行为和垃圾桶缺失、垃圾桶位移等环卫设施问题自动发现与快速处置，提高垃圾清运作业和环卫设施监管力度。</p>
            <img class="animate__animated" :class="animateShow5.show3 ? 'animate__fadeInUp' : ''" src="@/assets/solution/video_img4.png">
          </div>
        </div>
      </div>
      <div class="content4">
        <div class="content4-wrapper">
          <div class="label animate__animated" :class="animateShow5.show4 ? 'animate__fadeInUp' : ''">环卫管理子系统</div>
          <div class="text animate__animated" :class="animateShow5.show4 ? 'animate__fadeInUp' : ''">
            <p>通过智能视频监控识别分析技术，实现对环卫作业效果、环卫作业车辆、环卫设施、废弃物终端处置的监管，使环卫作业问题能够及早发现、快速解决。</p>
            <p>基于城市治理平台，针对暴露垃圾、道路不洁、垃圾满溢等环卫事件进行智能识别并报警，对各类不文明行为进行远程广播喊话或定期公示，引导公众自我治理。</p>
          </div>
          <img class="animate__animated" :class="animateShow5.show4 ? 'animate__fadeInUp' : ''" src="@/assets/solution/video_img5.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Solution',
  metaInfo: {
    title: '智慧城管|垃圾治理|垃圾分类|智慧环卫|智慧公厕|智能AI分析-海多普-解决方案',
    meta: [
      {
        name: 'keyWords',
        content: '海多普,解决方案,智慧城管,垃圾治理,垃圾分类,智慧环卫,智慧公厕,智能AI分析'
      },
      {
        name: 'description',
        content: '海多普，城市管理信息服务专家'
      }
    ]
  },
  data() {
    return {
      currIndex: 1,
      animateShow1: {
        show1: true,
        show2: false,
        show3: false
      },
      animateShow2: {
        show1: true,
        show2: false,
        show3: false
      },
      animateShow3: {
        show1: true,
        show2: false
      },
      animateShow4: {
        show1: true,
        show2: false,
        show3: false
      },
      animateShow5: {
        show1: true,
        show2: false,
        show3: false,
        show4: false
      }
    }
  },
  created() {
    if (this.$route.params.type) {
      this.currIndex = Number(this.$route.params.type)
    }
  },
  watch: {
    currIndex: function(val) {
      Object.assign(this.$data['animateShow' + val], this.$options.data()['animateShow' + val])
      if (val === 2) {
        if (window.scrollY >= 300) this.animateShow2.show2 = true
      }
      if (val === 4) {
        if (window.scrollY >= 200) this.animateShow4.show2 = true
      }
      if (val === 5) {
        if (window.scrollY >= 400) this.animateShow5.show2 = true
      }
    }
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll, true)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    handleScroll(e) {
      var scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop
      if (scrollTop >= 800) {
        this.animateShow1.show2 = true
      }
      if (scrollTop >= 1200) {
        this.animateShow1.show3 = true
        this.animateShow4.show3 = true
      }

      if (scrollTop >= 300) {
        this.animateShow2.show2 = true
      }
      if (scrollTop >= 1800) {
        this.animateShow2.show3 = true
      }

      if (scrollTop >= 1000) {
        this.animateShow3.show2 = true
      }

      if (scrollTop >= 200) {
        this.animateShow4.show2 = true
      }

      if (scrollTop >= 400) {
        this.animateShow5.show2 = true
      }
      if (scrollTop >= 900) {
        this.animateShow5.show3 = true
      }
      if (scrollTop >= 1500) {
        this.animateShow5.show4 = true
      }
      console.log(scrollTop)
    }
  }
}
</script>

<style lang="scss" scoped>
.solution {
  width: 100%;
  .banner {
    width: 100%;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
  .tabs {
    width: 100%;
    height: 64px;
    .tabs-wrapper {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      div {
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.active {
          background-color: #194898;
          color: #ffffff;
        }
      }
    }
  }
  .animate__animated {
    opacity: 0;
  }
  .animate__fadeInUp {
    animation-name: fadeInUpRes;
  }
  @keyframes fadeInUpRes {
    0% {
      opacity: 0;
      transform: translate3d(0, 30%, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .citymanager {
    width: 100%;
    background-color: #f7f8fc;
    padding-top: 20px;
    .citymanager-wrapper {
      width: 1200px;
      height: 660px;
      margin: 0 auto;
      background-color: #fff;
      padding: 50px 90px 100px 60px;
      .title {
        font-size: 40px;
        text-align: center;
        font-weight: bold;
      }
      .content {
        display: flex;
        margin-top: 66px;
        img {
          display: block;
        }
        .info {
          margin-top: 40px;
          font-size: 18px;
          line-height: 36px;
          padding-left: 90px;
          text-indent: 2em;
          text-align: justify;
        }
      }
    }
    .process {
      width: 100%;
      height: 400px;
      background-image: url('~@/assets/solution/zhcg_bg.jpg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .process-wrapper {
        width: 1200px;
        height: 400px;
        margin: 0 auto;
        padding-top: 68px;
        .pro-bar {
          display: flex;
          padding: 0 20px;
          justify-content: space-around;
          margin-top: 44px;
          position: relative;
          &::after {
            content: ' ';
            width: 1100px;
            height: 7px;
            background-color: #194898;
            position: absolute;
            top: 26px;
            left: 50%;
            transform: translateX(-50%);
          }
          .pro-item {
            width: 160px;
            height: 220px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            z-index: 99;
            .item-img {
              width: 56px;
              height: 56px;
              background: #194898;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .item-text {
              margin-top: 18px;
              text-align: center;
              color: #ffffff;
              p {
                font-size: 18px;
                &:not(:last-child) {
                  margin-bottom: 15px;
                }
              }
              .t {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 20px !important;
                line-height: 32px;
              }
            }
          }
        }
      }
    }
    .system {
      margin-top: 60px;
      overflow: hidden;
      .title {
        color: #194898;
        width: 150px;
        text-align: center;
        font-size: 32px;
        height: 50px;
        position: relative;
        margin: 0 auto;
        &::after {
          content: '';
          width: 50px;
          height: 7px;
          background-color: #adc3d6;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      img {
        display: block;
        margin: 32px auto;
      }
    }
  }
  .garbage {
    width: 100%;
    background-color: #f7f8fc;
    padding-top: 20px;
    .garbage-wrapper {
      width: 1200px;
      height: 300px;
      margin: 0 auto;
      background-color: #fff;
      padding: 50px 86px;
      .title {
        font-size: 40px;
        text-align: center;
        font-weight: bold;
      }
      .info {
        margin-top: 44px;
        line-height: 36px;
        text-indent: 2em;
        font-size: 18px;
      }
    }
    .thinking {
      margin-top: 47px;
      overflow: hidden;
      .title {
        color: #194898;
        width: 150px;
        text-align: center;
        font-size: 32px;
        height: 50px;
        position: relative;
        margin: 0 auto;
        &::after {
          content: '';
          width: 50px;
          height: 7px;
          background-color: #adc3d6;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      img {
        display: block;
        margin: 32px auto;
      }
    }
    .introduce {
      margin-top: 85px;
      overflow: hidden;
      .title {
        color: #194898;
        width: 150px;
        text-align: center;
        font-size: 32px;
        height: 50px;
        position: relative;
        margin: 0 auto;
        &::after {
          content: '';
          width: 50px;
          height: 7px;
          background-color: #adc3d6;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      img {
        display: block;
        margin: 32px auto;
      }
    }
  }
  .sanitation {
    width: 100%;
    background-color: #f7f8fc;
    padding: 20px 0;
    .sanitation-wrapper {
      width: 1200px;
      margin: 0 auto;
      background-color: #fff;
      padding-top: 50px;
      padding-bottom: 40px;
      .title {
        font-size: 40px;
        text-align: center;
        font-weight: bold;
      }
      .top {
        display: flex;
        margin-top: 60px;
        padding-left: 77px;
        padding-right: 130px;
        img {
          display: block;
        }
        .info {
          margin-top: 40px;
          font-size: 18px;
          line-height: 36px;
          padding-left: 90px;
          text-indent: 2em;
          text-align: justify;
        }
      }
      .bottom {
        padding: 0 68px;
        display: flex;
        margin-top: 70px;
        justify-content: space-between;
        .item {
          width: 510px;
          img {
            display: block;
            width: 100%;
            height: 341px;
          }
          p {
            text-align: center;
            line-height: 31px;
            margin-top: 30px;
          }
        }
      }
    }
    .characteristic {
      margin-top: 70px;
      overflow: hidden;
      .title {
        color: #194898;
        width: 150px;
        text-align: center;
        font-size: 32px;
        height: 50px;
        position: relative;
        margin: 0 auto;
        &::after {
          content: '';
          width: 50px;
          height: 7px;
          background-color: #adc3d6;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .content {
        width: 1180px;
        height: 455px;
        margin: 50px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        .card {
          width: 364px;
          height: 210px;
          background: #ffffff;
          box-shadow: 0px 2px 20px rgba(86, 86, 86, 0.16);
          border-radius: 8px;
          .lable {
            font-size: 22px;
            padding: 36px 0 0 47px;
          }
          div {
            display: flex;
            justify-content: space-between;
            margin-top: 28px;
            padding: 0 40px;
            img {
              display: block;
              width: 60px;
              height: 60px;
              margin-top: 5px;
            }
            p {
              width: 210px;
              line-height: 27px;
            }
          }
        }
        &::after {
          content: '';
          width: 364px;
        }
      }
    }
  }
  .toilet {
    width: 100%;
    padding-top: 20px;
    .toilet-wrapper {
      width: 1200px;
      height: 300px;
      margin: 0 auto;
      background-color: #fff;
      padding: 50px 86px;
      .title {
        font-size: 40px;
        text-align: center;
        font-weight: bold;
      }
      .info {
        margin-top: 44px;
        line-height: 36px;
        text-indent: 2em;
        font-size: 18px;
      }
    }
    .reform {
      margin-top: 47px;
      overflow: hidden;
      .title {
        color: #194898;
        width: 150px;
        text-align: center;
        font-size: 32px;
        height: 50px;
        position: relative;
        margin: 0 auto;
        &::after {
          content: '';
          width: 50px;
          height: 7px;
          background-color: #adc3d6;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      img {
        display: block;
        margin: 53px auto;
      }
    }
    .introduce {
      margin-top: 85px;
      overflow: hidden;
      .title {
        color: #194898;
        width: 150px;
        text-align: center;
        font-size: 32px;
        height: 50px;
        position: relative;
        margin: 0 auto;
        &::after {
          content: '';
          width: 50px;
          height: 7px;
          background-color: #adc3d6;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      img {
        display: block;
        margin: 93px auto;
      }
    }
  }
  .video {
    width: 100%;
    padding-top: 69px;
    padding-bottom: 87px;
    .title {
      font-size: 40px;
      text-align: center;
      font-weight: bold;
    }
    .content1 {
      width: 1200px;
      height: 280px;
      margin: 0 auto;
      margin-top: 86px;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      .left {
        width: 496px;
        .label {
          color: #194898;
          font-size: 26px;
          margin-bottom: 35px;
          font-weight: bold;
        }
        .text {
          line-height: 31px;
          font-size: 18px;
          text-indent: 2em;
        }
      }
      img {
        display: block;
        width: 627px;
        height: 220px;
        margin-top: 20px;
      }
    }
    .content2 {
      width: 100%;
      height: 395px;
      margin-top: 50px;
      background-color: #efeff1;
      display: flex;
      justify-content: center;
      align-items: center;
      .content2-wrapper {
        width: 1200px;
        height: 265px;
        display: flex;
        justify-content: space-between;
        padding-right: 57px;
        .left {
          width: 650px;
          .label {
            color: #194898;
            font-size: 26px;
            margin-bottom: 30px;
            font-weight: bold;
          }
          .text {
            p {
              line-height: 31px;
              font-size: 18px;
              text-indent: 2em;
            }
          }
        }
        img {
          display: block;
          width: 418px;
        }
      }
    }
    .content3 {
      padding-top: 82px;
      .content3-wrapper {
        width: 1200px;
        height: 538px;
        margin: 0 auto;
        display: flex;
        .left, .right {
          height: 100%;
          color: #ffffff;
          padding: 83px 32px 0 40px;
          .label {
            font-size: 26px;
            margin-bottom: 45px;
            font-weight: bold;
          }
          .text {
            font-size: 18px;
            line-height: 31px;
            text-indent: 2em;
          }
          img {
            display: block;
          }
        }
        .left {
          width: 538px;
          background-color: #194898;
          img {
            margin-top: 20px;
          }
        }
        .right {
          width: 662px;
          background-color: #6893DB;
          img {
            margin-top: 40px;
          }
        }
      }
    }
    .content4 {
      margin-top: 60px;
      .content4-wrapper {
        width: 1200px;
        margin: 0 auto;
        .label {
          padding-left: 20px;
          color: #194898;
          font-size: 26px;
          font-weight: bold;
        }
        .text {
          margin-top: 35px;
          padding-left: 20px;
          padding-right: 20px;
          p {
            font-size: 18px;
            line-height: 31px;
            text-indent: 2em;
          }
        }
        img {
          display: block;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
